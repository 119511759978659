import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';

const FamilyLeave = () => {
  const [userInfo, setUserInfo] = useState("");
  const [userD, setUserD] = useState({});
  const [searchUser, setSearchUser] = useState("");
  const [searchFamily, setSearchFamily] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [userList, setUserList] = useState([]);
  const [familyList, setFamilyList] = useState([]);

  const searchUsers = async (username) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASEURL4}/admin/familyImage/searchFamily`, {
        params: { search: username, type: "1" }
      });
      if (data.success == "1") {
        setUserList(data.details);
        setSelectedUser(null);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const searchFamilies = async (familyName) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASEURL4}/admin/familyImage/searchFamily`, {
        params: { search: familyName, type: "0" }
      });
      if (data.success == "1") {
        setFamilyList(data.details);
        setSelectedFamily(null);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const debouncedSearchUser = useCallback(
    _.debounce((username) => searchUsers(username), 1000),
    []
  );

  const debouncedSearchFamily = useCallback(
    _.debounce((familyName) => searchFamilies(familyName), 1000),
    []
  );

  const assignLeader = async () => {
    if (selectedUser && selectedFamily) {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASEURL4}/admin/familyImage/assignLeader`, null, {
          params: {
            id: selectedUser._id,
            familyId: selectedFamily._id
          }
        });
        if (data.success == 1) {
          toast.success(data.messge);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Please select a user and a family");
    }
  };

  const leaveFam = async () => {
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_BASEURL1}/admin/familyImage/leave`, {
        params: { userId: userD._id, familyId: userD.family._id }
      });
      if (data.success == 1) {
        toast.success("Successfully left the family");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const joinFamily = async () => {
    if (selectedUser && selectedFamily) {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASEURL4}/admin/familyImage/joinFamily`, null, {
          params: {
            id: selectedUser._id,
            familyId: selectedFamily._id
          }
        });
        if (data.success == 1) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Please select a user and a family");
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASEURL4}/admin/familyImage/leaveFamily`, {
          username: userInfo.toString()
        });
        if (data.success == 1) {
          setUserD(data.details[0]);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    getUserDetails();
  }, [userInfo]);

  useEffect(() => {
    if (searchUser) {
      debouncedSearchUser(searchUser);
    }
  }, [searchUser, debouncedSearchUser]);

  useEffect(() => {
    if (searchFamily) {
      debouncedSearchFamily(searchFamily);
    }
  }, [searchFamily, debouncedSearchFamily]);

  const filteredUserList = selectedUser ? [selectedUser] : userList;
  const filteredFamilyList = selectedFamily ? [selectedFamily] : familyList;

  return (
    <>
      <section className="content-header">
        <h1>Edit Family</h1>
        <ol className="breadcrumb">
          <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
          <li className="active">Leave Family</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form role="form" encType="multipart/form-data" id="form">
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Leave</h3>
                </div>
                <div className="box-body">
                  <div id="hide">
                    <div className="form-group" id='user'>
                      <label>Username*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="userId"
                        placeholder="username"
                        onChange={(e) => setUserInfo(e.target.value)}
                      />
                      <div className="form-error4 text-danger"></div>
                    </div>
                    <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                      <label>Family</label>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <div>
                          {userD && userD.familyImage !== null && (
                            <img
                              src={`${userD?.family && userD?.familyImage}`}
                              style={{ borderRadius: "50px" }}
                              height={50}
                              width={50}
                            />
                          )}
                          {userD.familyImage === null && <span>Not in family</span>}
                        </div>
                        <div>
                          <h5 style={{ color: "black", fontWeight: "bold" }}>{userD?.family?.name}</h5>
                        </div>
                      </div>
                      <div className="form-error5 text-danger"></div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      disabled={userD.familyImage !== null ? false : true}
                      defaultValue="Leave"
                      onClick={(e) => {
                        e.preventDefault();
                        leaveFam();
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>

            <form role="form" encType="multipart/form-data" id="assign-form">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Assign Leader / Join Family</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Search User*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by username"
                      onChange={(e) => setSearchUser(e.target.value)}
                    />
                    <ul>
                      {filteredUserList.map((user) => (
                        <li
                          key={user._id}
                          onClick={() => setSelectedUser(user)}
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                            cursor: "pointer",
                            backgroundColor: selectedUser?._id === user._id ? "#f0f0f0" : "transparent"
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_BASEURL1}/${user.picture}`}
                            alt={user.username}
                            style={{ borderRadius: "50%", width: "50px", height: "50px" }}
                          />
                          <h5 style={{ color: "black", fontWeight: "bold" }}>{user.username}</h5>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="form-group">
                    <label>Search Family*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by family name"
                      onChange={(e) => setSearchFamily(e.target.value)}
                    />
                    <ul>
                      {filteredFamilyList.map((family) => (
                        <li
                          key={family._id}
                          onClick={() => setSelectedFamily(family)}
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                            cursor: "pointer",
                            backgroundColor: selectedFamily?._id === family._id ? "#f0f0f0" : "transparent"
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_BASEURL1}/${family.image}`}
                            alt={family.name}
                            style={{ borderRadius: "50%", width: "50px", height: "50px" }}
                          />
                          <h5 style={{ color: "black", fontWeight: "bold" }}>{family.name}</h5>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="form-group" style={{marginRight:"20px"}}>
                    <input
                      type="submit"
                      className="btn btn-success pull-left"
                      value = "Assign Leader"
                      defaultValue="Assign"
                      onClick={(e) => {
                        e.preventDefault();
                        const userConfirmed = window.confirm("Current Leader of this family will no longer in any family. Are you sure you want to do this ?");
                        if(userConfirmed)
                        assignLeader();
                      }}
                    />
                  </div>
                  <div className="form-group " style={{marginRight:"10px"}}>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      value = "Join Family"
                      defaultValue="Join"
                      onClick={(e) => {
                        e.preventDefault();
                        const userConfirmed = window.confirm("User will leave current family. Are you sure you want to do this ?");
                        if(userConfirmed)
                        joinFamily();
                      }}
                      />
                  </div>
                      </div>
                </div>
            </form>

            {/* Join Family Form */}
          </div>
        </div>
      </section>
    </>
  );
};

export default FamilyLeave;
