  import React, { useContext, useEffect, useState,useCallback } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const AdminHostRecord = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;

  const [hostRecord, setHostRecord] = useState([]);
  // const [hostList, setHostLists] = useState([]);
  const [agencycode, setAgencyCode] = useState([]);
  const [allRecord, setAllRecord] = useState([]);
  const [selectAll, setSelectAll] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [selectedHosts, setSelectedHosts] = useState([]);
  const [Diamond, setDiamond] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [searchQuery, setSearchQuery] = useState("");
  // console.lg  og(hostRecord);
  useEffect(() => {
    fetchAdminHostRecord();
  }, [entriesPerPage]);

  ////////////////

  console.log(allRecord);
  // console.log(agencycode);
  // console.log(hostRecord);
  // console.log(currentPage);
  // console.log(entriesPerPage);
  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    const updatedHostRecord = hostRecord.map((record) => ({
      ...record,
      isSelected: newSelectAll,
    }));
    setHostRecord(updatedHostRecord);
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      // If all hosts are selected, update selectedHosts with all host IDs
      const allHostIds = hostRecord.map((record) => record._id);
      setSelectedHosts(allHostIds);
    } else {
      // If all hosts are unselected, clear selectedHosts
      setSelectedHosts([]);
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedHostRecord = [...hostRecord];
    updatedHostRecord[index].isSelected = !updatedHostRecord[index].isSelected;
    setHostRecord(updatedHostRecord);

    const hostId = updatedHostRecord[index]._id;

    console.log(hostId);
    if (updatedHostRecord[index].isSelected) {
      // Add host ID to selectedHosts array
      setSelectedHosts([...selectedHosts, hostId]);
    } else {
      // Remove host ID from selectedHosts array
      setSelectedHosts(selectedHosts.filter((id) => id !== hostId));
    }
  };

  ///////////////

  // ------------
  const deletm = async () => {
    try {
      let valData = { id: [selectedHosts] };
      console.log(valData);
      // return;
      const data = await axios.delete(
        `${baseUrl}/admin/livetime/deletemultiple`,
        { data: { id: selectedHosts } }
      );
      if (data.data.success == 1) {
        toast.success(data.data.message);

        fetchAdminHostRecord();
      } else {
        toast.error(data.data.message);
      }
      console.log(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  // -------pegination----------
  const indexOfLastHost = currentPage * entriesPerPage;
  const indexOfFirstHost = indexOfLastHost - entriesPerPage;
  const currentHostList = hostRecord;
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // const totalPages = ;
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, parseInt(currentPage) + 2);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };
  //-------------
  // console.log(currentPage);
  useEffect(() => {
    fetchAdminHostRecord();
  }, [currentPage]);
  // console.log(currentHostList);
  const renderSerialNumber = (index) => {
    return (currentPage - 1) * entriesPerPage + index + 1;
  };
  const fetchAdminHostRecord = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/host/hostrecord?page=${currentPage}&limit=${entriesPerPage}`
        // `https://mastishortss.com/video/admin/host/hostrecord?page=${currentPage}&limit=${entriesPerPage}`
      );

      if (data) {
        const diamond = data.data.diamond;
        setHostRecord(data.data.data);
        setAgencyCode(data.data);
        setAllRecord(data);
        setDiamond(diamond);
        setTotalPages(data.data.totalPages);
      } else {
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log(hostRecord);
  // console.log(allRecord);
  const agencyadminlistsearch = async (query = "") => {
    try {
      setHostRecord([])
      const response = await axios.get(`${process.env.REACT_APP_BASEURL3}/admin/host/search`, {
        params: {
          search: query,
        },
      });
      setHostRecord(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value)
      if(value!="")
      agencyadminlistsearch(value);
    else{
      fetchAdminHostRecord()
    }
    }, 1000),
    []
  );
  return (
    <>
      <section className="content-header">
        <h1>Host Record</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host Record</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      ent
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  <div className="form-group col-xs-4">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  className="bg-primary px-4 rounded-pill border-0  text-white"
                  style={{
                    border: "none",
                    color: "white",
                    borderRadius: "10px",
                    marginRight: "20px",
                    padding: "6px",
                    width: "100px",
                  }}
                  onClick={toggleSelectAll}
                >
                  Select All
                </button>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteModal"
                  className="bg-danger px-4 rounded-2  mx-1  text-white"
                  style={{
                    border: "none",
                    color: "white",
                    backgroundColor: "red",
                    borderRadius: "10px",
                    padding: "6px",
                    width: "100px",
                  }}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    let connfir = window.confirm(
                      "Are you sure Delete selected item "
                    );

                    if (connfir) {
                      deletm();
                      toast.success("deleted Selected item ", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        progress: undefined,
                        theme: "light",
                      });
                    }
                  }}
                >
                  Delete
                </button>

                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Select.</th>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Host Name</th>
                      <th>Agency</th>
                      <th>Minutes</th>
                      <th>Diamonds</th>
                      <th>Join Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {currentHostList?.map((host, i) => (
                      <tr key={host._id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={host.isSelected}
                            onChange={() => handleCheckboxChange(i)}
                          />
                        </td>
                        <td>{renderSerialNumber(i)}</td>
                        <td>
                          <img
                            src={`${baseUrl}${host.image}`}
                            alt=""
                            style={{ height: "3rem", width: "3rem" }}
                          />
                        </td>
                        <td>{host.channelName}</td>
                        <td>{host.agencyCode}</td>

                        <td>{host.totalTime}</td>
                        <td>{host.totaldiamond}</td>
                        <td>{(new Date(host.createdAt)).toLocaleString()}</td>

                        <td>
                          <span
                            className={`label  ${
                              host.status == "archive"
                                ? "label-info"
                                : host.status == "live"
                                ? "label-success"
                                : "label-danger"
                            }`}
                          >
                            {host.status == "archive"
                              ? "Archive"
                              : host.status == "live"
                              ? "Live"
                              : ""}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination links */}

                <div className="row">
                  <div className="col-md-12">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminHostRecord;
