import Dashboard from "./Components/AdminPages/Dashboard";
import Login from "./Components/AdminPages/Login";
import Profile from "./Components/AdminPages/Profile";
import Aside from "./Components/Includes/Aside";
import Footer from "./Components/Includes/Footer";
import Header from "./Components/Includes/Header";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Context } from "./Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// user
import User from "./Components/User/User";
import ViewUser from "./Components/User/ViewUser";
import Liveuser from "./Components/User/Liveuser";
import PushMessage from "./Components/User/PushMessage";
// host
import ApplyHost from "./Components/Host/ApplyHost";
import AppHost from "./Components/Host/AppHost";
// Bd
import AddBd from "./Components/Bd/AddBd";
import ManageBd from "./Components/Bd/ManageBd";
import EditBd from "./Components/Bd/EditBd";
import ViewBdData from "./Components/Bd/ViewBdData";
// agency
import AddAgency from "./Components/Agency/AddAgency";
import ManageAgency from "./Components/Agency/ManageAgency";
// coin agency
import AddCoin from "./Components/CoinPanel/AddCoin";
import ManageCoin from "./Components/CoinPanel/ManageCoin";
// vip
import ManageVip from "./Components/Vip/ManageVip";
import AddVip from "./Components/Vip/AddVip";
import EditVip from "./Components/Vip/EditVip";
// banner
import AddBanner from "./Components/Banner/AddBanner";
import ManageBanner from "./Components/Banner/ManageBanner";
// bagge
import ManageBadges from "./Components/Badges/ManageBadges";
import AddBadges from "./Components/Badges/AddBadges";
// tag
import ManageTag from "./Components/Tags/ManageTag";
import AddTag from "./Components/Tags/AddTag";
// gift
import AddGift from "./Components/Gift/AddGift";
import ManageGift from "./Components/Gift/ManageGift";
// live gift
import AddLiveGift from "./Components/LiveGift/AddLiveGift";
import ManageLiveGift from "./Components/LiveGift/ManageLiveGift";
import AddLiveGiftCategory from "./Components/LiveGift/AddLiveGiftCategory";
import LiveGiftCategory from "./Components/LiveGift/LiveGiftCategory";
// entry effect
import AddGarage from "./Components/Garage/AddGarage";
import ManageGarage from "./Components/Garage/ManageGarage";
// frames
import AddFrames from "./Components/Frames/AddFrames";
import ManageFrames from "./Components/Frames/ManageFrames";
// themes
import AddTheme from "./Components/Themes/AddTheme";
import ManageTheme from "./Components/Themes/ManageTheme";
// recharge
import SendCoins from "./Components/Recharge/SendCoins";
import RechargeHistory from "./Components/Recharge/RechargeHistory";
// level
import ManageLevel from "./Components/Level/ManageLevel";
import AddLevel from "./Components/Level/AddLevel";
import EditBanner from "./Components/Banner/EditBanner";
import EditTag from "./Components/Tags/EditTag";
import EditGift from "./Components/Gift/EditGift";
import EditGarage from "./Components/Garage/EditGarage";
import EditFrames from "./Components/Frames/EditFrames";
import EditLiveGiftCategory from "./Components/LiveGift/EditLiveGiftCategory";
import EditLiveGift from "./Components/LiveGift/EditLiveGift";
import EditTheme from "./Components/Themes/EditTheme";
import EditCoin from "./Components/CoinPanel/EditCoin";
import EditAgency from "./Components/Agency/EditAgency";
import AgencyDashboard from "./Components/Agency/Dashboard";
import AgencyLogin from "./Components/Agency/Login";
import AgencyProfile from "./Components/Agency/Profile";
import CoinPanelDashboard from "./Components/CoinPanel/Dashboard";
import CoinPanelProfile from "./Components/CoinPanel/Profile";
import CoinPanelLogin from "./Components/CoinPanel/Login";
import EditLevel from "./Components/Level/EditLevel";
import ViewCoin from "./Components/CoinPanel/ViewCoin";
import HostRecord from "./Components/Agency/HostRecord";
import AgencyHost from "./Components/Agency/AgencyHost";
import ViewAgency from "./Components/Agency/ViewAgency";
import HostList from "./Components/Agency/HostList";
import ViewVip from "./Components/Vip/ViewVip";
import AddVipPack from "./Components/Vip/AddVipPack";
import SendCoinPanel from "./Components/CoinPanel/SendCoin";
import SendCoinHistory from "./Components/CoinPanel/SendCoinHistory";
import RecieveCoinHistory from "./Components/CoinPanel/RecieveCoinHistory";
import { useEffect } from "react";
import AddSongs from "./Components/Songs/AddSongs";
import ManageSongs from "./Components/Songs/ManageSongs";
import AddPlan from "./Components/Plan/AddPlan";
import ManagePlan from "./Components/Plan/ManagePlan";
import AddContent from "./Components/Content/AddContent";
import ManageContent from "./Components/Content/ManageContent";
import EditPlan from "./Components/Plan/EditPlan";
import FamilyCount from "./Components/Family/FamilyCount";
import Vulgar from "./Components/Vulgar/Vulgar";
import SecretKey from "./Components/SecretKey/SecretKey";
import UserLevel from "./Components/User/UserLevels";
import EditUserLevel from "./Components/User/EditUserLevel";
import AddUserLevel from "./Components/User/UserAddLevel";
import UpdateOld from "./Components/AdminPages/UpdateOld";
import AddEntryEffectsLevel from "./Components/Level/AddEntryEffectsLevel";
import AddFrameLevel from "./Components/Level/AddFrameLevel";
import FrameLevel from "./Components/Level/FrameLevel";
import EditFrameLevel from "./Components/Level/EditFrameLevel";
import BackgroundTheme from "./Components/BackgroundTheme";
import EditBackgroundTheme from "./Components/EditBackgroundTheme";
import Video from "./Components/Video";
import LiveTime from "./Components/LiveTime";
import Widthdrawal from "./Components/Widthdrawal/Withdrawal";
import ViewWidthdrawal from "./Components/Widthdrawal/ViewWidthdrawal";
import BdLogin from "./Components/BdPanel/BdLogin";
import AgencyAdminLogin from "./Components/AgencyAdmin/AgencyAdminLogin";
import AgencyAdminDashboard from "./Components/AgencyAdmin/Dashboard";

import BdDashboard from "./Components/BdPanel/Dashboard";
import AddAgencyAdmin from "./Components/BdPanel/AddAgencyAdmin";
import ViewAgencyAdmin from "./Components/BdPanel/ViewAgencyAdmin";
import ManageAgencyAdmin from "./Components/BdPanel/ManageAgencyAdmin";
import EditAgencyAdmin from "./Components/BdPanel/EditAgencyAdmin";
import BdProfile from "./Components/BdPanel/BdProfile";
import AaProfile from "./Components/AgencyAdmin/AaProfile";
import AdminHostRecord from "./Components/AdminPages/AdminHostRecord";
import AgencyLists from "./Components/AgencyAdmin/AgencyLists";
import AgencyView from "./Components/AgencyAdmin/ViewAgency";
import ReturnCoins from "./Components/Recharge/ReturnCoins";
import ManageAgencyByAdmin from "./Components/AgencyAdmin/ManageAgency";
import CoinPanelTransactionLists from "./Components/AdminPages/CoinPanelTransactionLists";
import AgencAdminAdd from "./Components/AdminPages/AgencAdminAdd";

import AgencAdminEdit from "./Components/AdminPages/AgencAdminEdit";
import AgencAdminView from "./Components/AdminPages/AgencAdminView";
import AgencAdminManage from "./Components/AdminPages/AgencAdminManage";
import AgencyListShow from "./Components/BdPanel/AgencyListShow";
import ViewUserr from "./Components/Agency/ViewUser";
import AgencyAdminHostList from "./Components/AgencyAdmin/AgencyAdminHostList";
import WithdrawalbdLists from "./Components/Widthdrawal/WithdrawalBdLists";
import BdHost from "./Components/BdPanel/BdHost";
import WithdrawalAgencyAdminLists from "./Components/Widthdrawal/WithdrawalAgencyAdminLists";
import WithdrawalAgencyLists from "./Components/Widthdrawal/WithdrawalAgencyLists";
import WithdrawalHostLists from "./Components/Widthdrawal/WithdrawalHostLists";
import BdManageAgency from "./Components/Bd/BdManageAgency";
import BdAddAgency from "./Components/Bd/BdAddAgency";
import BdAgencyListWithdrawal from "./Components/Bd/BdAgencyListWithdrawl";
import BdListWithdrawal from "./Components/Bd/BdListWithdrawl";
import EditBdAgency from "./Components/BdPanel/EditBdAgency";
import BdLiveUser from "./Components/Bd/BdLiveUser";
import FamilyLeave from "./Components/Family/FamilyLeave";
import BdHostLists from "./Components/Bd/BdHostlists";
import Splashimag from "./Components/AdminPages/Splashimag";
import AgencyAdminWithdrawalLists from "./Components/AgencyAdmin/AgencyWithdrawalLists";
import HostWithdrawalLists from "./Components/AgencyAdmin/HostWithdrawalLists";
import AgencyHostsWithdrawalLists from "./Components/Agency/AgencyHostsWithdrawalLists";
import BdlistsHome from "./Components/Bd/BdlistsHome";
import Homepage from "./Components/Landingpage/Homepage";
import Otpsent from "./Components/Agency/Otpsent";
import Forgetpass from "./Components/Agency/Forgetpass";
import AgadminForgetpass from "./Components/AgencyAdmin/AgadminForgetpass";
import AgAdminOtpsent from "./Components/AgencyAdmin/AgAdminOtpsent";
import BdOtpsent from "./Components/BdPanel/BdOtpsent";
import BdForgetpass from "./Components/BdPanel/BdForgetpass";
import AddCommission from "./Components/Commission/AddCommission";
import ViewCommission from "./Components/Commission/ViewCommission";
import FamilyWithdrawal from "./Components/Widthdrawal/FamilyWithdrawal";
import FamilyMembersWithdrawal from "./Components/Widthdrawal/FamilyMemebersWithdrawal";
import AgencyRanking from "./Components/AdminPages/AgencyRanking";
import ReturnCoinHistory from "./Components/CoinPanel/ReturnHistory";
import FamilyWalletWidthdrawal from "./Components/Widthdrawal/FamilyWalletWithd";
import RemovedRanking from "./Components/AdminPages/RemovedRanking";
import FamilyWithdrawalbdadmin from "./Components/WithdrawlStored/FamilyWithBDadmin";
import FamilyWithdrawalAAadmin from "./Components/WithdrawlStored/FamilyWithAAadmin";
import FamilyWithdrawalAgadmin from "./Components/WithdrawlStored/FamilyWithAgadmin";
import FamilyWithdrawaladmin from "./Components/WithdrawlStored/FamilyWithfamiliesadmin";
import FamilyWithdrawalbd from "./Components/WithdrawlStored/FamilyWithfamiliesbd";
import FamilyWithdrawalAgbd from "./Components/WithdrawlStored/FamilyWithAgbd";
import FamilyWithdrawalAAbd from "./Components/WithdrawlStored/FamilyWithAAbd";
import Ads from "./Components/Banner/Ads";
import AddAds from "./Components/Banner/AddAds";
import BdBanneduser from "./Components/Bd/BdBanneduser";
import AdminBannedUsers from "./Components/AdminPages/AdminBannedUsers";
import TotalWithdrawalbdadmin from "./Components/WithdrawlStored/TotalWithBDadmin";
import TotalWithdrawalAAadmin from "./Components/WithdrawlStored/TotalWithAAadmin";
import TotalWithdrawalAgadmin from "./Components/WithdrawlStored/TotalWithAgadmin";
import TotalWithdrawaladmin from "./Components/WithdrawlStored/TotalWithHostadmin";
import Balance from "./Components/AdminPages/Balance";
import ViewTransactions from "./Components/Family/ViewTransactions";
import ManageSenior from "./Components/AgencyAdmin/SeniorAdmin";
import ManageSeniorAgency from "./Components/Agency/SeniorAgency";
import GetTargetWithdrawlBD from "./Components/TargetWithdrawl/GetTargetWithdrawlBD";
import AddTargetWithBD from "./Components/TargetWithdrawl/AddTargetWithdrawlBD";
import EditTargetWithBD from "./Components/TargetWithdrawl/EditTargetWithdrawlBD";
import GetTargetWithdrawlAA from "./Components/TargetWithdrawl/GetTargetWithdrawlAA";
import AddTargetWithAA from "./Components/TargetWithdrawl/AddTargetWithdrawlAA";
import EditTargetWithAA from "./Components/TargetWithdrawl/EditTargetWithdrawlAA";
import GetTargetWithdrawl from "./Components/TargetWithdrawl/GetTargetWithdrawl";
import AgencyListReq from "./Components/Agency/AgencyListReq";
import RankingComponent from "./Components/AdminPages/SendingRecievingRanking";

function App() {
  // var BaseUrl = process.env.REACT_APP_BASEURL;
  // console.log(BaseUrl);
  let x = window.location.pathname.toString();
  const adminLogin = x[0];
  let ans = x[0] + x[1] + x[2] + x[3] + x[4] + x[5];
  let agency = ans + x[6] + x[7];
  let agencyLogin = ans + x[6];
  let coin = x[0] + x[1] + x[2] + x[3] + x[4];
  let coinDash = x[0] + x[1] + x[2] + x[3] + x[4] + x[5];
  let bd = x[0] + x[1] + x[2];
  let bdDash = x[0] + x[1] + x[2] + x[3];
  let agencyadmin =
    x[0] +
    x[1] +
    x[2] +
    x[3] +
    x[4] +
    x[5] +
    x[6] +
    x[7] +
    x[8] +
    x[9] +
    x[10] +
    x[11];
  let agencyadminDash =
    x[0] +
    x[1] +
    x[2] +
    x[3] +
    x[4] +
    x[5] +
    x[6] +
    x[7] +
    x[8] +
    x[9] +
    x[10] +
    x[11] +
    x[12];
  // console.log(coinDash);
  let coinDetail = sessionStorage.getItem("coin");
  let coinDetails = JSON.parse(coinDetail);
  useEffect(() => {}, [coinDetails]);

  const AdminRoutes = () => (
    <>
      <Header />
      {sessionStorage.getItem("data") ? (
        <Aside />
      ) : (
        (window.location.href = "/admin")
      )}{" "}
      <div
        className="content-wrapper"
        style={{
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          minHeight: "calc(100vh - 100px)",
        }}
      >
        <Routes>
          {/* admin  */}
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/videos" element={<Video />} />
          <Route path="/admin/livetime" element={<LiveTime />} />
          {/* splash Image  */}
          <Route path="/admin/splashimage" element={<Splashimag />} />
          {/* user  */}
          <Route path="/admin/manageUser" element={<User />} />
          <Route path="/admin/ViewUser" element={<ViewUser />} />
          <Route path="/admin/viewwidthdrawal" element={<ViewWidthdrawal />} />
          <Route path="/admin/liveUser" element={<Liveuser />} />
          <Route path="/admin/pushMessage" element={<PushMessage />} />
          {/* host  */}
          <Route path="/admin/applyHost" element={<ApplyHost />} />
          <Route path="/admin/pendingHost" element={<AppHost status={0} />} />
          <Route path="/admin/approvedHost" element={<AppHost status={1} />} />
          <Route path="/admin/rejectedHost" element={<AppHost status={2} />} />
          <Route path="/admin/hostrecord" element={<AdminHostRecord />} />
          {/* Bd */}
          <Route path="/admin/addBd" element={<AddBd />} />
          <Route path="/admin/manageBd" element={<ManageBd />} />
          <Route path="/admin/EditBd" element={<EditBd />} />
          <Route path="/admin/viewBd" element={<ViewBdData />} />

          {/* coin  */}
          <Route path="/admin/addCoin" element={<AddCoin />} />
          <Route path="/admin/editCoin" element={<EditCoin />} />
          <Route
            path="/admin/trasactionlists/:uniqueId"
            element={<CoinPanelTransactionLists />}
          />
          {/* <Route path="/admin/viewCoin" element={<ViewCoin/>} /> */}
          <Route path="/admin/manageCoin" element={<ManageCoin />} />
          {/* agenc admin  */}
          <Route path="/admin/addagencyadmin" element={<AgencAdminAdd />} />
          {/* <Route
            path="/admin/manageagencyadmin"
            element={<AgencAdminManage />}
          /> */}
          <Route path="/admin/editagencyadmin" element={<AgencAdminEdit />} />
          <Route path="/admin/viewagencyadmin" element={<AgencAdminView />} />
          <Route
            path="/admin/manageagencyadmin"
            element={<AgencAdminManage />}
          />

          {/* agency  */}
          <Route path="/admin/addAgency" element={<AddAgency />} />
          <Route path="/admin/editAgency" element={<EditAgency />} />
          <Route path="/admin/viewAgency" element={<ViewAgency status={1} />} />
          <Route path="/admin/manageAgency" element={<ManageAgency />} />
          {/* vip  */}
          <Route path="/admin/addVip" element={<AddVip />} />
          <Route path="/admin/editVip" element={<EditVip />} />
          <Route path="/admin/viewVipPack" element={<ViewVip />} />
          <Route path="/admin/addVipPack" element={<AddVipPack />} />
          <Route path="/admin/manageVip" element={<ManageVip />} />
          {/* banner  */}
          <Route path="/admin/addBanner" element={<AddBanner />} />
          <Route path="/admin/editBanner" element={<EditBanner />} />
          <Route path="/admin/manageBanner" element={<ManageBanner />} />
          {/* badge  */}
          <Route path="/admin/addBadge" element={<AddBadges />} />
          <Route path="/admin/manageBadges" element={<ManageBadges />} />
          {/* tag  */}
          <Route path="/admin/manageTag" element={<ManageTag />} />
          <Route path="/admin/addTag" element={<AddTag />} />
          <Route path="/admin/editTag" element={<EditTag />} />
          {/* gift  */}
          <Route path="/admin/addGift" element={<AddGift />} />
          <Route path="/admin/editGift" element={<EditGift />} />
          <Route path="/admin/manageGift" element={<ManageGift />} />
          {/* live gift  */}
          <Route path="/admin/addLiveGift" element={<AddLiveGift />} />
          <Route path="/admin/editLiveGift" element={<EditLiveGift />} />
          <Route path="/admin/manageLiveGift" element={<ManageLiveGift />} />
          {/* Songs */}
          <Route path="/admin/addSongs" element={<AddSongs />} />
          <Route path="/admin/manageSongs" element={<ManageSongs />} />
          {/* Plan */}
          <Route path="/admin/addPlan" element={<AddPlan />} />
          <Route path="/admin/managePlan" element={<ManagePlan />} />
          <Route path="/admin/editplan" element={<EditPlan />} />

          {/* Video Content */}
          <Route path="/admin/addContent" element={<AddContent />} />
          <Route path="/admin/manageContent" element={<ManageContent />} />
          <Route path="/admin/backgroundtheme" element={<BackgroundTheme />} />
          <Route
            path="/admin/editbackgroundtheme"
            element={<EditBackgroundTheme />}
          />

          {/*Family count */}
          <Route path="/admin/familycount" element={<FamilyCount />} />

          {/*Vulgar*/}
          <Route path="/admin/vulgar" element={<Vulgar />} />

          {/*SecretKey*/}
          <Route path="/admin/secretkey" element={<SecretKey />} />

          <Route
            path="/admin/addLiveGiftCategory"
            element={<AddLiveGiftCategory />}
          />
          <Route
            path="/admin/editLiveGiftCategory"
            element={<EditLiveGiftCategory />}
          />
          <Route
            path="/admin/manageLiveGiftCategory"
            element={<LiveGiftCategory />}
          />
          {/* entry effect  */}
          <Route path="/admin/addEntryEffect" element={<AddGarage />} />
          <Route path="/admin/editEntryEffect" element={<EditGarage />} />
          <Route path="/admin/manageEntryEffect" element={<ManageGarage />} />
          {/* frames  */}
          <Route path="/admin/addFrames" element={<AddFrames />} />
          <Route path="/admin/editFrames" element={<EditFrames />} />
          <Route path="/admin/manageFrames" element={<ManageFrames />} />
          {/* themes  */}
          <Route path="/admin/addTheme" element={<AddTheme />} />
          <Route path="/admin/editTheme" element={<EditTheme />} />
          <Route path="/admin/manageTheme" element={<ManageTheme />} />
          {/* recharge  */}
          <Route path="/admin/sendCoins" element={<SendCoins />} />
          <Route path="/admin/rechargeHistory" element={<RechargeHistory />} />
          {/* level  */}
          <Route path="/admin/manageLevel" element={<ManageLevel />} />
          {/* Below route is for entry effects level  */}
          <Route path="/admin/entryEffectslevel" element={<UserLevel />} />
          <Route path="/admin/framelevel" element={<FrameLevel />} />

          <Route path="/admin/updatewallpaper" element={<EditUserLevel />} />
          <Route path="/admin/updateframelevel" element={<EditFrameLevel />} />
          <Route path="/admin/userlevelupload" element={<AddUserLevel />} />
          <Route
            path="/admin/addentryeffectslevel"
            element={<AddEntryEffectsLevel />}
          />
          <Route path="/admin/addframelevel" element={<AddFrameLevel />} />

          <Route path="/admin/addLevel" element={<AddLevel />} />
          <Route path="/admin/editLevel" element={<EditLevel />} />
          <Route path="/admin/viewCoin" element={<ViewCoin />} />
          <Route path="/admin/updateUser" element={<UpdateOld />} />
          <Route path="/admin/returncoins" element={<ReturnCoins />} />
          <Route
            path="/admin/widthdrawal/pending"
            element={<Widthdrawal status={0} />}
          />
          <Route
            path="/admin/widthdrawal/approved"
            element={<Widthdrawal status={1} />}
          />
          <Route
            path="/admin/widthdrawal/rejected"
            element={<Widthdrawal status={2} />}
          />
          <Route
            path="/admin/viewwidthdrawal/:id"
            element={<ViewWidthdrawal />}
          />
          <Route
            path="/admin/withdrawalbdlists"
            element={<WithdrawalbdLists />}
          />
          <Route path="/admin/bdlistshome" element={<BdlistsHome />} />
          <Route path="/admin/list" element={<WithdrawalAgencyAdminLists />} />
          <Route
            path="/admin/list/agencylist"
            element={<WithdrawalAgencyLists />}
          />
          <Route
            path="/admin/list/hostlists"
            element={<WithdrawalHostLists />} 
          />
          <Route path="/admin/familyleave" element={<FamilyLeave />} />

          {/* Commission */}
          <Route path="/admin/addcommission" element={<AddCommission />} />
          <Route path="/admin/viewcommission" element={<ViewCommission />} />
          <Route path="/admin/agencyRanking" element={<AgencyRanking />} />
          <Route path="/admin/withdrawalfamilylists" element={<FamilyWithdrawal />} />
          <Route path="/admin/withdrawalfamilymemberslists" element={<FamilyMembersWithdrawal/>} />
          <Route
            path="/admin/pendingfamilywalletwith"
            element={<FamilyWalletWidthdrawal status={0} />}
          />
          <Route
            path="/admin/approvedfamilywalletwith"
            element={<FamilyWalletWidthdrawal status={1} />}
          />
          <Route
            path="/admin/rejectedfamilywalletwith"
            element={<FamilyWalletWidthdrawal status={2} />}
          />
          <Route
            path="/admin/removedranking"
            element={<RemovedRanking />}
          />
          <Route
            path="/admin/storedwithdrawalfamilylists"
            element={<FamilyWithdrawalbdadmin />}
          />
          <Route
            path="/admin/storedwithdrawalfamilyAAlists"
            element={<FamilyWithdrawalAAadmin />}
          />
          <Route
            path="/admin/storedwithdrawalfamilyAglists"
            element={<FamilyWithdrawalAgadmin />}
          />
          <Route
            path="/admin/storedwithdrawalffamilylists"
            element={<FamilyWithdrawaladmin />}
          />
          <Route path="/admin/addd" element={<Ads type={0} />}/>
          <Route path="/admin/liveaddd" element={<Ads type={1} />}/>
          <Route path="/admin/addAd" element={<AddAds type={0}/>}/>
          <Route path="/admin/liveaddAd" element={<AddAds type={1}/>}/>
          <Route path="/admin/addEvent" element={<AddAds type={2}/>}/>
          <Route path="/admin/bannedUsers" element={<AdminBannedUsers />}/>
          <Route path="/admin/storedwithdrawalbdlists" element={<TotalWithdrawalbdadmin />}/>
          <Route path="/admin/storedwithdrawalAAlists" element={<TotalWithdrawalAAadmin />}/>
          <Route path="/admin/storedwithdrawalAglists" element={<TotalWithdrawalAgadmin />}/>
          <Route path="/admin/storedwithdrawallists" element={<TotalWithdrawaladmin />}/>
          <Route path="/admin/balance" element={<Balance />}/>
          <Route path="/admin/ViewTransactions" element={<ViewTransactions />}/>
          <Route path="/admin/GetTargetWithdrawl" element={<GetTargetWithdrawl />}/>
          <Route path="/admin/sendingRecievingRanking" element={<RankingComponent />}/>
        </Routes>
      </div>
      <Footer />
    </>
  );
  const BdRoutes = () => (
    <>
      {sessionStorage.getItem("bd") ? (
        <Header />
      ) : (
        (window.location.href = "/bd")
      )}
      {sessionStorage.getItem("bd") ? <Aside /> : <></>}{" "}
      <div
        className="content-wrapper"
        style={{
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          minHeight: "calc(100vh - 100px)",
        }}
      >
        <Routes>
          <Route path="/bd/dashboard" element={<BdDashboard />} />
          <Route path="/bd/addagencyadmin" element={<AddAgencyAdmin />} />
          <Route path="/bd/manageagencyadmin" element={<ManageAgencyAdmin />} />
          <Route path="/bd/viewagencyadmin" element={<ViewAgencyAdmin />} />
          <Route path="/bd/editagencyadimin" element={<EditAgencyAdmin />} />
          <Route path="/bd/profile" element={<BdProfile />} />
          <Route path="/bd/viewAgency" element={<AgencyListShow />} />
          <Route path="/bd/pandinghost" element={<BdHost status={0} />} />
          <Route path="/bd/approvedhost" element={<BdHost status={1} />} />
          <Route path="/bd/rejectedhost" element={<BdHost status={2} />} />
          <Route path="/bd/addagency" element={<BdAddAgency />} />
          <Route path="/bd/list" element={<BdAgencyListWithdrawal />} />
          <Route path="/bd/list/agencylist" element={<BdListWithdrawal />} />
          <Route path="/bd/hostlists" element={<BdHostLists />} />
          <Route path="/bd/editagency" element={<EditBdAgency />} />
          <Route path="/bd/liveuser" element={<BdLiveUser />} />
          <Route path="/bd/bannedusers" element={<BdBanneduser />} />

          <Route path="/bd/manageagency" element={<BdManageAgency />} />
          <Route path="/bd/pendingagencyListReq" element={<AgencyListReq status={0}/>} />
          <Route
            path="/bd/storedwithdrawalfamilyAAlists"
            element={<FamilyWithdrawalAAbd />}
          />
          <Route
            path="/bd/storedwithdrawalfamilyAglists"
            element={<FamilyWithdrawalAgbd />}
          />
          <Route
            path="/bd/storedwithdrawalffamilylists"
            element={<FamilyWithdrawalbd />}
          />
          <Route
            path="/bd/getTargetWith"
            element={<GetTargetWithdrawlBD />}
          />
          <Route
            path="/bd/addTargetWith"
            element={<AddTargetWithBD />}
          />
          <Route
            path="/bd/editTargetWith"
            element={<EditTargetWithBD />}
          />
        </Routes>
      </div>
      <Footer />
    </>
  );
  const AgencyRoutes = () => (
    <>
      {sessionStorage.getItem("agency") ? (
        <Header />
      ) : (
        (window.location.href = "/agency")
      )}
      {sessionStorage.getItem("agency") ? <Aside /> : <></>}{" "}
      <div
        className="content-wrapper"
        style={{
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          minHeight: "calc(100vh - 100px)",
        }}
      >
        <Routes>
          <Route path="/agency/dashboard" element={<AgencyDashboard />} />
          <Route path="/agency/profile" element={<AgencyProfile />} />
          <Route path="/agency/hostRecord" element={<HostRecord />} />
          <Route path="/agency/agencyHost" element={<AgencyHost />} />
          <Route path="/agency/viewAgency" element={<ViewAgency />} />
          <Route path="/agency/viewUser" element={<ViewUserr />} />
          <Route path="/agency/pendingHost" element={<HostList status={0} />} />
          <Route
            path="/agency/hostwithdrawal"
            element={<AgencyHostsWithdrawalLists />}
          />
          <Route
            path="/agency/approvedHost"
            element={<HostList status={1} />}
          />
          <Route
            path="/agency/rejectedHost"
            element={<HostList status={2} />}
          />
          <Route
            path="/agency/Seniors"
            element={<ManageSeniorAgency />}
          />
          {/* <Route path="/otpSent" element={<Otpsent />} /> */}
         
        </Routes>
      </div>
      <Footer />
    </>
  );

  const CoinRoutes = () => (
    <>
      <Header />
      <Aside />
      <div
        className="content-wrapper"
        style={{
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          minHeight: "calc(100vh - 100px)",
        }}
      >
        <Routes>
          <Route path="/coin/dashboard" element={<CoinPanelDashboard />} />
          <Route path="/coin/profile" element={<CoinPanelProfile />} />
          <Route path="/coin/sendcoin" element={<SendCoinPanel />} />
          <Route path="/coin/history" element={<SendCoinHistory />} />
          <Route path="/coin/received" element={<RecieveCoinHistory />} />
          <Route path="/coin/returnhistory" element={<ReturnCoinHistory />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
  const AgencyAdminRoutes = () => (
    <>
      {sessionStorage.getItem("agencyadmin") ? (
        <Header />
      ) : (
        (window.location.href = "/agencyadmin")
      )}
      {sessionStorage.getItem("agencyadmin") ? <Aside /> : <></>}{" "}
      <div
        className="content-wrapper"
        style={{
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto",
          minHeight: "calc(100vh - 100px)",
        }}
      >
        <Routes>
          <Route
            path="/agencyadmin/dashboard"
            element={<AgencyAdminDashboard />}
          />
          <Route path="/agencyadmin/addagency" element={<AddAgency />} />
          <Route
            path="/agencyadmin/manageagency"
            element={<ManageAgencyByAdmin />}
          />
          <Route
            path="/agencyadmin/viewagency"
            element={<ViewAgency status={1} />}
          />
          <Route path="/agencyadmin/editagency" element={<EditAgency />} />
          <Route path="/agencyadmin/aaprofile" element={<AaProfile />} />
          <Route path="/agencyadmin/agencylists" element={<AgencyLists />} />
          <Route path="/agencyadmin/hosts/:id" element={<AgencyView />} />
          <Route
            path="agencyadmin/pendinghost"
            element={<AgencyAdminHostList status={0} />}
          />
          <Route
            path="/agencyadmin/approvedhost"
            element={<AgencyAdminHostList status={1} />}
          />
          <Route
            path="/agencyadmin/rejectedhost"
            element={<AgencyAdminHostList status={2} />}
          />
          <Route
            path="/agencyadmin/agencywithdrawal"
            element={<AgencyAdminWithdrawalLists />}
          />
          <Route
            path="/agencyadmin/agencywithdrawalhosts"
            element={<HostWithdrawalLists />}
          />
          <Route
            path="/agencyadmin/Seniors"
            element={<ManageSenior />}
          />
          <Route
            path="/agencyadmin/getTargetWith"
            element={<GetTargetWithdrawlAA />}
          />
          <Route
            path="/agencyadmin/addTargetWith"
            element={<AddTargetWithAA />}
          />
          <Route
            path="/agencyadmin/editTargetWith"
            element={<EditTargetWithAA />}
          />
        </Routes>
      </div>
      <Footer />
    </>
  );

  const adminLoginpage = () => (
    <Routes>
      <Route path="/" element={<Homepage />} />
    </Routes>
  );
  const AdminLoginRoute = () => (
    <Routes>

      <Route path="/qwertyuiop/zxcvbnm/$" element={<Login />} />
    </Routes>
  );
  const AgencyLoginRoute = () => (
    <Routes>
      <Route path="/agency" element={<AgencyLogin />} />
      <Route path="/agency/otpsent" element={<Otpsent />} />
      <Route path="/agency/forgetpass" element={<Forgetpass />} />
    </Routes>
  );
  const CoinLoginRoute = () => (
    <Routes>
      <Route path="/coin" element={<CoinPanelLogin />} />
    </Routes>
  );
  const BdLoginRoutes = () => (
    <>
      <Routes>
        <Route path="/bd" element={<BdLogin />} />
        <Route path="/bd/otpsent" element={<BdOtpsent />} />
        <Route path="/bd/forgetpass" element={<BdForgetpass />} />
      </Routes>
    </>
  );
  //////////
  const AgencyAdminLoginRoutes = () => (
    <>
      <Routes>
        <Route path="/agencyadmin" element={<AgencyAdminLogin />} />
        <Route path="/agencyadmin/otpsent" element={<AgAdminOtpsent />} />
      <Route path="/agencyadmin/forgetpass" element={<AgadminForgetpass />} />
      </Routes>
    </>
  );
  /////////
  return (
    <>
      <Context>
        {/* {
          adminLogin === '/' ? adminLoginpage() : <></>
        } */}
        {ans === "/admin"
          ? AdminRoutes()
          : agencyadminDash === "/agencyadmin/"
          ? AgencyAdminRoutes()
          : agencyadmin === "/agencyadmin"
          ? AgencyAdminLoginRoutes()
          : agency === "/agency/"
          ? AgencyRoutes()
          : agencyLogin === "/agency"
          ? AgencyLoginRoute()
          : coinDash === "/coin/"
          ? CoinRoutes()
          : coin === "/coin"
          ? CoinLoginRoute()
          : bdDash === "/bd/"
          ? BdRoutes()
          : bd === "/bd"
          ? BdLoginRoutes()
          :x==="/qwertyuiop/zxcvbnm/$"? AdminLoginRoute():<></>}
      </Context>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
