import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AddAds = ({ type }) => {
  const [formFields, setFormFields] = useState({
    name: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    gifts: [],
  });
  const [giftsOptions, setGiftsOptions] = useState([]);
  const navigate = useNavigate();

  // Fetch gifts from the API on component mount
  useEffect(() => {
    const fetchGifts = async () => {
      try {
        const response = await axios.get(
          "https://mastishortss.com/v3/admin/livegifts/getlivegifts"
        );
        if (response.data.success === 1) {
          setGiftsOptions(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching gifts:", error);
      }
    };

    fetchGifts();
  }, []);

  const handleInputChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  // Handle gift checkbox selection with a maximum of 3 selections
  const handleGiftSelection = (giftId) => {
    const selectedGifts = [...formFields.gifts];

    // Check if the gift is already selected
    if (selectedGifts.includes(giftId)) {
      // If already selected, remove it
      const updatedGifts = selectedGifts.filter((id) => id !== giftId);
      setFormFields({ ...formFields, gifts: updatedGifts });
    } else {
      // If not selected and less than 3 gifts are selected, add the gift
      if (selectedGifts.length < 3) {
        setFormFields({ ...formFields, gifts: [...selectedGifts, giftId] });
      } else {
        toast.error("You can select a maximum of 3 gifts.");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (type == 2) {
      // formData.append("name", formFields.name);
      // formData.append("startDate", formFields.startDate);
      // formData.append("endDate", formFields.endDate);
      // formData.append("startTime", formFields.startTime);
      // formData.append("endTime", formFields.endTime);
      formData.append("gifts", JSON.stringify(formFields.gifts)); // Sending gift IDs as an array
    }

    try {
      event.target.reset();

      let response;
      if (type == 2) {
        response = await axios.post(
          `${process.env.REACT_APP_BASEURL5}/admin/banner/addEvents`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BASEURL1}/admin/banner/addAd?type=${type}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      if (response.data.success === 1) {
        toast.success(type == 2 ? "Event successfully added" : "Image successfully added");
        navigate("/admin/liveaddd");
      } else {
        toast.error("Failed to add");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error(type == 2 ? "Failed to add event" : "Failed to add image");
    }
  };

  return (
    <>
      <section className="content-header">
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Add Ad</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Image*</label>
                    <input type="file" className="form-control" name="image" />
                  </div>

                  {type == 2 && (
                    <>
                      <div className="form-group">
                        <label>Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formFields.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>Start Date*</label>
                        <input
                          type="date"
                          className="form-control"
                          name="startDate"
                          value={formFields.startDate}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>End Date*</label>
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          value={formFields.endDate}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>Start Time*</label>
                        <input
                          type="time"
                          className="form-control"
                          name="startTime"
                          value={formFields.startTime}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>End Time*</label>
                        <input
                          type="time"
                          className="form-control"
                          name="endTime"
                          value={formFields.endTime}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label>Gifts*</label>
                        <div className="mb-3">
                          {giftsOptions.map((gift) => (
                            <div key={gift._id} className="form-check position-relative">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value={gift._id}
                                checked={formFields.gifts.includes(gift._id)}
                                onChange={() => handleGiftSelection(gift._id)}
                                id={`gift-${gift._id}`} // Unique ID for accessibility
                              />
                              <label
                                className="form-check-label d-flex align-items-center p-2 border rounded shadow-sm hover-effect"
                                htmlFor={`gift-${gift._id}`}
                              >
                                <img
                                  src={`${process.env.REACT_APP_BASEURL1}${gift.giftimage}`}
                                  alt={gift.title}
                                  width="30"
                                  className="me-2"
                                />
                                <span className="me-2">{gift.title}</span> - <span className="text-muted">{gift.coin} coins</span>
                              </label>
                            </div>
                          ))}
                        </div>

                        <style jsx>{`
  .hover-effect {
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .hover-effect:hover {
    background-color: #f8f9fa; /* Light gray background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
  }

  .form-check-input:checked + .form-check-label {
    background-color: #e2f0d9; /* Light green background when checked */
    border: 1px solid #28a745; /* Green border when checked */
  }
`}</style>


                        <small className="text-muted">Select up to 3 gifts.</small>
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success pull-right"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddAds;
