import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from 'axios'
import _ from "lodash";

const ManageAgency = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  // const { agencyPanel, viewAgencyPanel } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
 const [search,setSearch] = useState("")
 const [lists,setLists] = useState([])
  const [de, setDe] = useState(0);
  const [agencyPanel, setAgencyPanel] = useState([]);
  const [totalPages, setTotalPages] = useState(1);


  const viewAgencyPanel = async (id) => {
    try {
      let data = await fetch(`${baseUrl}/admin/agencyPanel/getAgencyPanel?page=${currentPage}&limit=${entriesPerPage}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setAgencyPanel(a.data);
      setTotalPages(a.total)

    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    viewAgencyPanel();
  }, [de,entriesPerPage,currentPage]);

  const searchData = async (searchD) => {
    try {
      const {data} = await axios.get(`${baseUrl}/admin/agencyPanel/search?q=${searchD}`)
     if(data.success == 1) {
      setAgencyPanel(data.details)
      setTotalPages(data.total)
     }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearch(value);
      if(value!="")
        searchData(value);
    else viewAgencyPanel()
    }, 1000),
    []
  );
  const debounce = (fx,delay) => {
    let timer = null;
    return (...args) => {
      if(timer) {
        clearTimeout(timer)
      }
       timer = setTimeout(() => {
        fx(args)
      }, delay);
    }
  }
  let debounceR = debounce(searchData,300);




  // Logic to paginate the users


  // console.log(currentUsers)
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
 

 
  return (
    <>
      <section className="content-header">
        <h1>Manage Agency</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Manage Agency</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box-header">
              <h3 className="box-title">
                <Link
                  to="/admin/addAgency"
                  style={{ fontSize: "14px" }}
                  className="btn btn-block btn-success btn-xs"
                >
                  Add Agency
                </Link>
              </h3>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-5 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </div>

                  <div
                    class="col-md-5"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <div
                      class="main-data-single-field"
                      style={{ width: "fit-content" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Search
                      </span>
                      <span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{
                            borderRadius: "4px",
                            borderStyle: "groove",
                            width: "150px",
                          }}
                          onChange={(e) => {
                            handleSearchChange(e.target.value);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="success-message"></div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Agency Code</th>
                      <th>Agency Name</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {  Array.isArray(agencyPanel) &&
                      agencyPanel.map((item, index) => {
                        let i =  index + 1;
                        return (
                          <tr>
                              <td>{((totalPages - (currentPage-1)*entriesPerPage) - i) + 1}</td>
                              <td>
                              <img
                                src={`${baseUrl}${item.image}`}
                                alt=""
                                width={"40px"} height={"40px"} style={{borderRadius:"100%"}}
                              />
                            </td>
                            <td>{item.uniqueId}</td>
                            <td>{item.name}</td>
                            <td>{item.number}</td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/admin/viewAgency?id=${item._id}`}
                                      >
                                        View
                                      </Link>
                                    </li>
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/admin/editAgency?id=${item._id}`}
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    {/* <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to delete this item?"
                                          );
                                          if (confirmDelete) {
                                            $.ajax({
                                              url: `${process.env.REACT_APP_BASEURL}/admin/coinPanel/deleteCoinPanel/${item._id}`,
                                              type: "delete",
                                              dataType: "json",
                                              success: function (res) {
                                                if (res.success === 1) {
                                                  toast.success(res.message);
                                                  setDe(de + 1);
                                                } else {
                                                  toast.error(res.message);
                                                }
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </li> */}
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}

                    {/* Repeat rows as needed */}
                  </tbody>
                </table>
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{totalPages}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageAgency;
